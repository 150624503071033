import { reportConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,  
  courseData: [],
  transactionData: null,  
};

const reportReducer = (state = initState, action) => {  
  switch (action.type) {     
    case reportConstants.GET_COURSE_REPORTS_REQUEST:
    case reportConstants.GET_TRANSACTION_REPORTS_REQUEST:    
      return {
        ...state,
        loading: true,
      };
    
    case reportConstants.GET_COURSE_REPORTS_SUCCESS:
      return {
        ...state,
        courseData: action.payload,
        loading: false,
      };

    case reportConstants.GET_TRANSACTION_REPORTS_SUCCESS:
      return {
        ...state,
        transactionData: action.payload,
        loading: false,
      };

    case reportConstants.GET_COURSE_REPORTS_FAILURE:
    case reportConstants.GET_TRANSACTION_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  
              
    case reportConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case reportConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default reportReducer;