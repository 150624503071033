import { appConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  sessions: [],
  selectedSession: null,
  courseTypes: [],
  examTypes: [],
  studentCategories: [],
  courses: [],
  admissionForms: [],
  examForms: [],
  admissions: [],
  redirect: ""
};

const appReducer = (state = initState, action) => {  
  switch (action.type) {    
    case appConstants.GET_INITIAL_DATA_REQUEST:
    case appConstants.UPDATE_PASSWORD_REQUEST:   
    case appConstants.UPDATE_AVATAR_REQUEST:     
      return {
        ...state,
        loading: true,
      };

    case appConstants.SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload
      }

    case appConstants.GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        sessions: action.payload?.sessions,        
        selectedSession: action.payload?.sessions[0]?.id,
        courseTypes: action.payload?.courseTypes,
        courses: action.payload?.courses,
        examTypes: action.payload?.examTypes,
        studentCategories: action.payload?.studentCategories,       
        faculties: action.payload?.faculties,
        users: action.payload?.users,
        admissionForms: action.payload?.admissionForms,
        examForms: action.payload?.examForms,
        admissions: action.payload?.admissions,
        loading: false,
      };

    case appConstants.SET_SELECTED_SESSION:
      return {
        ...state,
        selectedSession: action.payload,
      };

    case appConstants.UPDATE_PASSWORD_SUCCESS:
    case appConstants.UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        message: action.payload?.message,
        loading: false,
      };
        
    case appConstants.GET_INITIAL_DATA_FAILURE:
    case appConstants.UPDATE_PASSWORD_FAILURE: 
    case appConstants.UPDATE_AVATAR_FAILURE:       
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  

    case appConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case appConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default appReducer;