import { dataConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  admissions: [],
  course: null,
  admissionForms: [],
  examForms: [],
  students: [],
  sessionStudentCount: null,  
};

const dataReducer = (state = initState, action) => {  
  switch (action.type) { 
    case dataConstants.GET_SESSION_DATA_REQUEST:
    case dataConstants.GET_COURSE_DETAILS_REQUEST:    
    case dataConstants.GET_COLLEGE_STUDENTS_REQUEST:
    case dataConstants.PROMOTE_STUDENTS_REQUEST:    
    case dataConstants.DELETE_SESSION_STUDENT_REQUEST:      
      return {
        ...state,
        loading: true,
      };

    case dataConstants.GET_SESSION_DATA_SUCCESS:
      return {
        ...state,
        admissions: action.payload?.admissions,
        admissionForms: action.payload?.admissionForms,
        examForms: action.payload?.examForms,
        sessionStudentCount: action.payload?.studentCount,
        loading: false,
      };

    case dataConstants.GET_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        course: action.payload,
        loading: false,
      };

    case dataConstants.GET_COLLEGE_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.payload,
        loading: false,
      };

    case dataConstants.PROMOTE_STUDENTS_SUCCESS:    
    case dataConstants.DELETE_SESSION_STUDENT_SUCCESS:
      return {
        ...state,
        message: action.payload?.message,
        loading: false,
      };

    case dataConstants.GET_STUDENTS_SUCCESS:
      return {
        ...state,
        studentsData: action.payload,
        loading: false,
      };

    case dataConstants.GET_SESSION_DATA_FAILURE:
    case dataConstants.GET_COURSE_DETAILS_FAILURE:
    case dataConstants.GET_COLLEGE_STUDENTS_FAILURE:
    case dataConstants.PROMOTE_STUDENTS_FAILURE:    
    case dataConstants.DELETE_SESSION_STUDENT_FAILURE:
    case dataConstants.GET_STUDENTS_FAILURE:    
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  
              
    case dataConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case dataConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default dataReducer;