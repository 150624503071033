export const menuConstants = {
  ACTIVE_ITEM: "ACTIVE_ITEM",
  ACTIVE_COMPONENT: "ACTIVE_COMPONENT",
  OPEN_DRAWER: "OPEN_DRAWER",
  OPEN_COMPONENT_DRAWER: "OPEN_COMPONENT_DRAWER"
};

export const userConstants = {  
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  COMPLETE_PROFILE_REQUEST: "COMPLETE_PROFILE_REQUEST",
  COMPLETE_PROFILE_SUCCESS: "COMPLETE_PROFILE_SUCCESS",
  COMPLETE_PROFILE_FAILURE: "COMPLETE_PROFILE_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const appConstants = {
  GET_INITIAL_DATA_REQUEST: "GET_INITIAL_DATA_REQUEST",
  GET_INITIAL_DATA_SUCCESS: "GET_INITIAL_DATA_SUCCESS",
  GET_INITIAL_DATA_FAILURE: "GET_INITIAL_DATA_FAILURE",  
  SET_SELECTED_SESSION: "SET_SELECTED_SESSION",
  SET_REDIRECT: "SET_REDIRECT",
  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  UPDATE_AVATAR_REQUEST: "UPDATE_AVATAR_REQUEST",
  UPDATE_AVATAR_SUCCESS: "UPDATE_AVATAR_SUCCESS",
  UPDATE_AVATAR_FAILURE: "UPDATE_AVATAR_FAILURE",
  RESET: "RESET"
}

export const dataConstants = {  
  GET_SESSION_DATA_REQUEST: "GET_SESSION_DATA_REQUEST",
  GET_SESSION_DATA_SUCCESS: "GET_SESSION_DATA_SUCCESS",
  GET_SESSION_DATA_FAILURE: "GET_SESSION_DATA_FAILURE",
  GET_COURSE_DETAILS_REQUEST: "GET_COURSE_DETAILS_REQUEST",
  GET_COURSE_DETAILS_SUCCESS: "GET_COURSE_DETAILS_SUCCESS",
  GET_COURSE_DETAILS_FAILURE: "GET_COURSE_DETAILS_FAILURE",
  GET_COLLEGE_STUDENTS_REQUEST: "GET_COLLEGE_STUDENTS_REQUEST",
  GET_COLLEGE_STUDENTS_SUCCESS: "GET_COLLEGE_STUDENTS_SUCCESS",
  GET_COLLEGE_STUDENTS_FAILURE: "GET_COLLEGE_STUDENTS_FAILURE",
  PROMOTE_STUDENTS_REQUEST: "PROMOTE_STUDENTS_REQUEST",
  PROMOTE_STUDENTS_SUCCESS: "PROMOTE_STUDENTS_SUCCESS",
  PROMOTE_STUDENTS_FAILURE: "PROMOTE_STUDENTS_FAILURE",  
  DELETE_SESSION_STUDENT_REQUEST: "DELETE_SESSION_STUDENT_REQUEST",
  DELETE_SESSION_STUDENT_SUCCESS: "DELETE_SESSION_STUDENT_SUCCESS",
  DELETE_SESSION_STUDENT_FAILURE: "DELETE_SESSION_STUDENT_FAILURE",
  ADD_STUDENT_TO_COURSE_REQUEST: "ADD_STUDENT_TO_COURSE_REQUEST",
  ADD_STUDENT_TO_COURSE_SUCCESS: "ADD_STUDENT_TO_COURSE_SUCCESS",
  ADD_STUDENT_TO_COURSE_FAILURE: "ADD_STUDENT_TO_COURSE_FAILURE",    
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const admissionConstants = {
  GET_ADMISSION_REQUEST: "GET_ADMISSION_REQUEST",
  GET_ADMISSION_SUCCESS: "GET_ADMISSION_SUCCESS",
  GET_ADMISSION_FAILURE: "GET_ADMISSION_FAILURE",
  GET_ADMISSION_APPLICATIONS_REQUEST: "GET_ADMISSION_APPLICATIONS_REQUEST",
  GET_ADMISSION_APPLICATIONS_SUCCESS: "GET_ADMISSION_APPLICATIONS_SUCCESS",
  GET_ADMISSION_APPLICATIONS_FAILURE: "GET_ADMISSION_APPLICATIONS_FAILURE",
  GET_ONBOARD_LIST_REQUEST: "GET_ONBOARD_LIST_REQUEST",
  GET_ONBOARD_LIST_SUCCESS: "GET_ONBOARD_LIST_SUCCESS",
  GET_ONBOARD_LIST_FAILURE: "GET_ONBOARD_LIST_FAILURE",
  UPDATE_APPLICATION_STATUS_REQUEST: "UPDATE_APPLICATION_STATUS_REQUEST",
  UPDATE_APPLICATION_STATUS_SUCCESS: "UPDATE_APPLICATION_STATUS_SUCCESS",
  UPDATE_APPLICATION_STATUS_FAILURE: "UPDATE_APPLICATION_STATUS_FAILURE",
  ONBOARD_AS_STUDENTS_REQUEST: "ONBOARD_AS_STUDENTS_REQUEST",
  ONBOARD_AS_STUDENTS_SUCCESS: "ONBOARD_AS_STUDENTS_SUCCESS",
  ONBOARD_AS_STUDENTS_FAILURE: "ONBOARD_AS_STUDENTS_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const formConstants = {  
  GET_FORM_REQUEST: "GET_FORM_REQUEST",
  GET_ADMISSION_FORM_SUCCESS: "GET_ADMISSION_FORM_SUCCESS",
  GET_EXAM_FORM_SUCCESS: "GET_EXAM_FORM_SUCCESS",
  GET_FORM_FAILURE: "GET_FORM_FAILURE",
  GET_FORM_DATA_REQUEST: "GET_FORM_DATA_REQUEST",
  GET_ADMISSION_FORM_DATA_SUCCESS: "GET_ADMISSION_FORM_DATA_SUCCESS",
  GET_EXAM_FORM_DATA_SUCCESS: "GET_EXAM_FORM_DATA_SUCCESS",
  GET_FORM_DATA_FAILURE: "GET_FORM_DATA_FAILURE",
  GET_STUDENT_FORM_REQUEST: "GET_STUDENT_FORM_REQUEST",
  GET_STUDENT_ADMISSION_FORM_SUCCESS: "GET_STUDENT_ADMISSION_FORM_SUCCESS",
  GET_STUDENT_EXAM_FORM_SUCCESS: "GET_STUDENT_EXAM_FORM_SUCCESS",
  GET_STUDENT_FORM_FAILURE: "GET_STUDENT_FORM_FAILURE",
  SAVE_FORM_SUBJECT_SELECTION_REQUEST: "SAVE_FORM_SUBJECT_SELECTION_REQUEST",
  SAVE_FORM_SUBJECT_SELECTION_SUCCESS: "SAVE_FORM_SUBJECT_SELECTION_SUCCESS",
  SAVE_FORM_SUBJECT_SELECTION_FAILURE: "SAVE_FORM_SUBJECT_SELECTION_FAILURE",
  PAY_STUDENT_FORM_FEE_REQUEST: "PAY_STUDENT_FORM_FEE_REQUEST",
  PAY_STUDENT_FORM_FEE_SUCCESS: "PAY_STUDENT_FORM_FEE_SUCCESS",
  PAY_STUDENT_FORM_FEE_FAILURE: "PAY_STUDENT_FORM_FEE_FAILURE",
  NOTIFY_STUDENT_FORM_REQUEST: "NOTIFY_STUDENT_FORM_REQUEST",
  NOTIFY_STUDENT_FORM_SUCCESS: "NOTIFY_STUDENT_FORM_SUCCESS",
  NOTIFY_STUDENT_FORM_FAILURE: "NOTIFY_STUDENT_FORM_FAILURE",
  HANDLE_ADMIT_CARD_VISIBILITY_REQUEST: "HANDLE_ADMIT_CARD_VISIBILITY_REQUEST",
  HANDLE_ADMIT_CARD_VISIBILITY_SUCCESS: "HANDLE_ADMIT_CARD_VISIBILITY_SUCCESS",
  HANDLE_ADMIT_CARD_VISIBILITY_FAILURE: "HANDLE_ADMIT_CARD_VISIBILITY_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const resultConstants = {
  GET_STUDENTS_REQUEST: "GET_STUDENTS_REQUEST",
  GET_STUDENTS_SUCCESS: "GET_STUDENTS_SUCCESS",
  GET_STUDENTS_FAILURE: "GET_STUDENTS_FAILURE",
  SAVE_STUDENT_RESULT_REQUEST: "SAVE_STUDENT_RESULT_REQUEST",
  SAVE_STUDENT_RESULT_SUCCESS: "SAVE_STUDENT_RESULT_SUCCESS",
  SAVE_STUDENT_RESULT_FAILURE: "SAVE_STUDENT_RESULT_FAILURE",
  UPDATE_RESULT_VISIBILITY_REQUEST: "UPDATE_RESULT_VISIBILITY_REQUEST",
  UPDATE_RESULT_VISIBILITY_SUCCESS: "UPDATE_RESULT_VISIBILITY_SUCCESS",
  UPDATE_RESULT_VISIBILITY_FAILURE: "UPDATE_RESULT_VISIBILITY_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const studentConstants = {
  GET_STUDENTS_REQUEST: "GET_STUDENTS_REQUEST",
  GET_STUDENTS_SUCCESS: "GET_STUDENTS_SUCCESS",
  GET_STUDENTS_FAILURE: "GET_STUDENTS_FAILURE",
  GET_STUDENT_REQUEST: "GET_STUDENT_REQUEST",
  GET_STUDENT_SUCCESS: "GET_STUDENT_SUCCESS",
  GET_STUDENT_FAILURE: "GET_STUDENT_FAILURE",
  MANAGE_STUDENT_REQUEST: "MANAGE_STUDENT_REQUEST",
  MANAGE_STUDENT_SUCCESS: "MANAGE_STUDENT_SUCCESS",
  MANAGE_STUDENT_FAILURE: "MANAGE_STUDENT_FAILURE",
  ADD_STUDENT_TO_COURSE_REQUEST: "ADD_STUDENT_TO_COURSE_REQUEST",
  ADD_STUDENT_TO_COURSE_SUCCESS: "ADD_STUDENT_TO_COURSE_SUCCESS",
  ADD_STUDENT_TO_COURSE_FAILURE: "ADD_STUDENT_TO_COURSE_FAILURE",
  UPDATE_ROLL_NUMBER_REQUEST: "UPDATE_ROLL_NUMBER_REQUEST",
  UPDATE_ROLL_NUMBER_SUCCESS: "UPDATE_ROLL_NUMBER_SUCCESS",
  UPDATE_ROLL_NUMBER_FAILURE: "UPDATE_ROLL_NUMBER_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const reportConstants = {
  GET_COURSE_REPORTS_REQUEST: "GET_COURSE_REPORTS_REQUEST",
  GET_COURSE_REPORTS_SUCCESS: "GET_COURSE_REPORTS_SUCCESS",
  GET_COURSE_REPORTS_FAILURE: "GET_COURSE_REPORTS_FAILURE",  
  GET_TRANSACTION_REPORTS_REQUEST: "GET_TRANSACTION_REPORTS_REQUEST",
  GET_TRANSACTION_REPORTS_SUCCESS: "GET_TRANSACTION_REPORTS_SUCCESS",
  GET_TRANSACTION_REPORTS_FAILURE: "GET_TRANSACTION_REPORTS_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}