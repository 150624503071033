import { reportConstants } from "redux/constants";
import Axios from "utils/axios";

const transformCourseReport = (data) => {
  const courses = {};
  data?.forEach(row => {
    const { courseId, courseName, courseCode, classId, classCode, className, student_count, collegeCourseId } = row;

    if (!courses[courseId]) {
      courses[courseId] = {
        name: courseName,
        code: courseCode,
        id: courseId,
        collegeCourseId,
        courseClasses: []
      };
    }

    courses[courseId].courseClasses.push({
      id: classId,
      code: classCode,
      name: className,
      studentCount: student_count
    });
  });

  return Object.values(courses);
};

export const getCourseReport = (selectedSession) => {
  return async dispatch => {
    try{
      dispatch({ type: reportConstants.GET_COURSE_REPORTS_REQUEST });            
      let URL = `/college/reports/course/${selectedSession}`;      
      const { data } = await Axios.get(URL);      
      let courseData = {}
      if(data?.data?.courseData){
        courseData = transformCourseReport(data?.data?.courseData);
      }
      dispatch({ 
        type: reportConstants.GET_COURSE_REPORTS_SUCCESS,
        payload: courseData
      });            
    }catch(error){
      dispatch({ 
        type: reportConstants.GET_COURSE_REPORTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getTransactionReport = (selectedSession, filter) => {
  return async dispatch => {
    try{
      dispatch({ type: reportConstants.GET_TRANSACTION_REPORTS_REQUEST });            
      let URL = `/college/reports/transaction/${selectedSession}`;
      if(filter?.startDate && filter?.endDate) URL += `?startDate=${filter.startDate}&endDate=${filter.endDate}`;
      if(filter?.paymentMode) URL += `&paymentMode=${filter.paymentMode}`;      
      if(filter?.admissionFormId) URL += `&admissionFormId=${filter.admissionFormId}`;
      if(filter?.examFormId) URL += `&examFormId=${filter.examFormId}`;
      const { data } = await Axios.get(URL, filter);      
      dispatch({ 
        type: reportConstants.GET_TRANSACTION_REPORTS_SUCCESS,
        payload: data?.data
      });            
    }catch(error){
      dispatch({ 
        type: reportConstants.GET_TRANSACTION_REPORTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}