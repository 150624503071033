import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

const icons = {    
  AssignmentIndOutlinedIcon,
  CurrencyRupeeOutlinedIcon,
  SchoolOutlinedIcon
};

const students = {
  id: "students",
  title: "Students",
  type: "group",
  children: [
    {
      id: "manage-students",
      title: "Manage Students",
      type: "item",
      url: "/student",
      icon: icons.AssignmentIndOutlinedIcon,
      breadcrumbs: true,
      roles: [2]
    },
    {
      id: "students-list",
      title: "Students List(Course Wise)",
      type: "item",
      url: "/student/list",
      icon: icons.SchoolOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2, 3]
    },
    {
      id: "admission-form",
      title: "Admission Forms",
      type: "item",
      url: "/admission-form",
      icon: icons.CurrencyRupeeOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2]      
    },
    {
      id: "exam-form",
      title: "Exam Forms",
      type: "item",
      url: "/exam-form",
      icon: icons.CurrencyRupeeOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2]   
    },     
  ]
};

export default students;