import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';

const icons = {    
  AssessmentOutlinedIcon,
  CurrencyRupeeOutlinedIcon
};

const reports = {
  id: "reports",
  title: "Reports",
  type: "group",
  children: [
    {
      id: "courses-reports",
      title: "Course Reports",
      type: "item",
      url: "/reports/course",
      icon: icons.AssessmentOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2]
    },
    {
      id: "transactions-reports",
      title: "Transaction Reports",
      type: "item",
      url: "/reports/transaction",
      icon: icons.CurrencyRupeeOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2]
    }     
  ]
};

export default reports;