import { dataConstants } from "redux/constants";
import Axios from "utils/axios";

export const getSessionData = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_SESSION_DATA_REQUEST });      
      const { data } = await Axios.get(`/college/session-data?id=${id}`);            
      dispatch({ 
        type: dataConstants.GET_SESSION_DATA_SUCCESS,
        payload: { ...data?.data }
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_SESSION_DATA_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getCourseDetails = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_COURSE_DETAILS_REQUEST });
      const { data } = await Axios.get(`/course/${id}`);            
      dispatch({ 
        type: dataConstants.GET_COURSE_DETAILS_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_COURSE_DETAILS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getCollegeStudents = (sessionId, courseClassId, filter) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_COLLEGE_STUDENTS_REQUEST });      
      let URL = `/college/course/student?sessionId=${sessionId}&courseClassId=${courseClassId}`;
      if(filter?.status) URL += `&status=${filter.status}`;
      if(filter?.result) URL += `&result=${filter.result}`;
      if(filter?.category) URL += `&category=${filter.category}`;
      if(filter?.gender) URL += `&gender=${filter.gender}`
      const { data } = await Axios.get(URL);
      dispatch({ 
        type: dataConstants.GET_COLLEGE_STUDENTS_SUCCESS,
        payload: data?.data
      });           
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_COLLEGE_STUDENTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const promoteStudents = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.PROMOTE_STUDENTS_REQUEST });      
      const { data } = await Axios.post(`/college/promote/students`, form);
      dispatch({ 
        type: dataConstants.PROMOTE_STUDENTS_SUCCESS,
        payload: {
          message: data?.message,
        }
      });           
    }catch(error){
      dispatch({ 
        type: dataConstants.PROMOTE_STUDENTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const deleteSessionStudent = (id, info) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.DELETE_SESSION_STUDENT_REQUEST });      
      const { data } = await Axios.delete(`/college/course/student?id=${id}`);
      dispatch({ 
        type: dataConstants.DELETE_SESSION_STUDENT_SUCCESS,
        payload: {
          message: data?.message,
        }
      });   
      dispatch(getCollegeStudents(info?.selectedSession, info?.selectedClass, info?.filter));        
    }catch(error){
      dispatch({ 
        type: dataConstants.DELETE_SESSION_STUDENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}