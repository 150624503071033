import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Loading } from "./@extended/Loading";
import {   
  admissionConstants,
  appConstants, 
  dataConstants,
  formConstants, 
  reportConstants,
  resultConstants,
  studentConstants, 
  userConstants 
} from "redux/constants";

const MessageAndErrors = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();  
  const { message: admissionMsg, error: admissionErr, loading: admissionLoading } = useSelector(state => state.admission);
  const { message: appMsg, error: appErr, loading: appLoading } = useSelector(state => state.app);  
  const { message: dataMsg, error: dataErr, loading: dataLoading } = useSelector(state => state.data);    
  const { message: formMsg, error: formErr, loading: formLoading } = useSelector(state => state.form);
  const { message: reportMsg, error: reportErr, loading: reportLoading } = useSelector(state => state.report);
  const { message: resultMsg, error: resultErr, loading: resultLoading } = useSelector(state => state.result);
  const { message: studentMsg, error: studentErr, loading: studentLoading } = useSelector(state => state.student);
  const { message: userMsg, error: userErr, loading: userLoading, redirectLogin } = useSelector(state => state.user);

  useEffect(() => {    
    if(admissionMsg){
      toast.success(admissionMsg)
      dispatch({ type: admissionConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(admissionErr){
      toast.error(admissionErr)
      dispatch({ type: admissionConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(appMsg){
      toast.success(appMsg)
      dispatch({ type: appConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(appErr){
      toast.error(appErr)
      dispatch({ type: appConstants.RESET_ERROR_AND_MESSAGE })
    }    
    if(dataMsg){
      toast.success(dataMsg)
      dispatch({ type: dataConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(dataErr){
      toast.error(dataErr)
      dispatch({ type: dataConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(formMsg){
      toast.success(formMsg)
      dispatch({ type: formConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(formErr){
      toast.error(formErr)
      dispatch({ type: formConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(reportMsg){
      toast.success(reportMsg)
      dispatch({ type: reportConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(reportErr){
      toast.error(reportErr)
      dispatch({ type: reportConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(resultMsg){
      toast.success(resultMsg)
      dispatch({ type: resultConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(resultErr){
      toast.error(resultErr)
      dispatch({ type: resultConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(studentMsg){
      toast.success(studentMsg)
      dispatch({ type: studentConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(studentErr){
      toast.error(studentErr)
      dispatch({ type: studentConstants.RESET_ERROR_AND_MESSAGE })
    }    
    if(userMsg){
      toast.success(userMsg)
      dispatch({ type: userConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(userErr){
      toast.error(userErr)
      dispatch({ type: userConstants.RESET_ERROR_AND_MESSAGE })
    }  
    if(redirectLogin){      
      navigate(`/auth/login`)
      dispatch({ type: userConstants.RESET_ERROR_AND_MESSAGE })
    }  
  }, [
    dispatch,    
    admissionMsg,
    admissionErr,
    appMsg, 
    appErr,  
    dataMsg,
    dataErr,
    formMsg,
    formErr,
    reportMsg,
    reportErr,   
    resultMsg,
    resultErr,
    studentMsg,
    studentErr,
    userMsg, 
    userErr,
    redirectLogin,
    navigate
  ]);

  return (
    <>
      <ToastContainer         
        style={{ fontSize: "12px" }}  
        position="top-right"      
      />
      <Loading
        loading={ 
          admissionLoading ||         
          appLoading || 
          dataLoading || 
          formLoading || 
          reportLoading ||        
          resultLoading ||
          studentLoading ||
          userLoading
        }
      />      
    </>
  )
}

export default MessageAndErrors;