import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { appConstants } from "redux/constants";

const Dashboard = Loadable(lazy(() => import("pages/dashboard")));

const Courses = Loadable(lazy(() => import("pages/courses")));
const CourseDetails = Loadable(lazy(() => import("pages/courses/details")));

const Faculties = Loadable(lazy(() => import("pages/faculties")));
const Users = Loadable(lazy(() => import("pages/users")));

const Admissions = Loadable(lazy(() => import("pages/admissions")));
const AdmissionApplications = Loadable(lazy(() => import("pages/admissions/applications")));
const AdmissionOnboard = Loadable(lazy(() => import("pages/admissions/onboard")));

const AdmissionForm = Loadable(lazy(() => import("pages/forms/admission-form")));
const AdmissionFormDetails = Loadable(lazy(() => import("pages/forms/admission-form-details")));
const PayAdmissionFormFee = Loadable(lazy(() => import("pages/forms/pay-admission-form-fee")));

const ExamForm = Loadable(lazy(() => import("pages/forms/exam-form")));
const ExamFormDetails = Loadable(lazy(() => import("pages/forms/exam-form-details")));
const Marks = Loadable(lazy(() => import("pages/marks")));
const PayExamFormFee = Loadable(lazy(() => import("pages/forms/pay-exam-form-fee")));

const CourseStudents = Loadable(lazy(() => import("pages/students/course-students")));
const ManageStudent = Loadable(lazy(() => import("pages/students/manage-student")));
const Students = Loadable(lazy(() => import("pages/students")));
const Profile = Loadable(lazy(() => import("pages/profile")));
const CourseReport = Loadable(lazy(() => import("pages/reports/course")));
const TransactionReport = Loadable(lazy(() => import("pages/reports/transaction")));

const PrivateRoute = ({ children }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirect } = useSelector(state => state.app);
  const { userLoading, isAuthenticated } = useSelector(state => state.user);

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
      dispatch({
        type: appConstants.SET_REDIRECT,
        payload: ""
      });
    }
  }, [redirect, navigate, dispatch]);

  return !userLoading && 
    (!isAuthenticated ? 
      <Navigate to={`/auth/login?redirect=${encodeURIComponent(window.location.pathname)}`} />
    : children); 
};

const AuthorizedRoute = ({ roles, children }) => {
  const { user } = useSelector(state => state.user);  
  return user &&
    (roles?.includes(user?.role) ? children : <Navigate to="/404" />);
}

const MainRoutes = {
  path: "/",
  element: <PrivateRoute><MainLayout/></PrivateRoute>,
  children: [
    {
      path: "",
      element: <Dashboard/>
    },
    {
      path: "/courses",
      children: [
        {
          path: "",
          element: <Courses/>
        },
        {
          path: ":id",
          element: <CourseDetails/>
        },       
      ]      
    },
    {
      path: "/faculties",
      children: [
        {
          path: "",
          element: <Faculties/>
        },       
      ]
    },
    {
      path: "/users",
      children: [
        {
          path: "",
          element: <AuthorizedRoute
            roles={[1, 2]}
          >
            <Users/>
          </AuthorizedRoute>
        },       
      ]
    }, 
    {
      path: "/admissions",
      children: [
        {
          path: "",
          element: <Admissions/>
        },
        {
          path: "applications/:id",
          element: <AdmissionApplications/>
        },
        {
          path: "onboard/:id",
          element: <AuthorizedRoute
            roles={[2]}
          >
            <AdmissionOnboard/>
          </AuthorizedRoute>
        }       
      ]
    },
    {
      path: "/admission-form",
      children: [
        {
          path: "",
          element: <AuthorizedRoute
            roles={[1,2]}
          >
            <AdmissionForm/>
          </AuthorizedRoute>
        },
        {
          path: ":id",
          element: <AuthorizedRoute
            roles={[1,2]}
          >
            <AdmissionFormDetails/>
          </AuthorizedRoute>
        },
        {
          path: "pay-fee/:id",
          element: <AuthorizedRoute
            roles={[2]}
          >
            <PayAdmissionFormFee/>
          </AuthorizedRoute>
        }         
      ]      
    },   
    {
      path: "/exam-form",
      children: [
        {
          path: "",
          element: <AuthorizedRoute
            roles={[1,2]}
          >
            <ExamForm/>
          </AuthorizedRoute>
        },
        {
          path: ":id",
          element: <AuthorizedRoute
            roles={[1,2]}
          >
            <ExamFormDetails/>
          </AuthorizedRoute>
        },
        {
          path: "marks/:id",
          element: <AuthorizedRoute
            roles={[2]}
          >
            <Marks/>
          </AuthorizedRoute>
        },
        {
          path: "pay-fee/:id",
          element: <AuthorizedRoute
            roles={[2]}
          >
            <PayExamFormFee/>
          </AuthorizedRoute>
        }        
      ]      
    },
    {
      path: "/student",      
      children: [
        {
          path: "",
          element: <AuthorizedRoute
            roles={[2]}
          >
            <Students/>
          </AuthorizedRoute>
        },
        {
          path: "list",
          element: <CourseStudents/>
        },
        {
          path: "manage",
          element: <AuthorizedRoute
            roles={[2]}
          >
            <ManageStudent/>
          </AuthorizedRoute>
        }        
      ]
    },   
    {
      path: "profile",
      element: <Profile/>
    },
    {
      path: "reports",
      children: [
        {
          path: "course",
          element: <AuthorizedRoute
            roles={[1, 2]}
          >
            <CourseReport/>
          </AuthorizedRoute>
        },
        {
          path: "transaction",
          element: <AuthorizedRoute
            roles={[1, 2]}
          >
            <TransactionReport/>
          </AuthorizedRoute>
        }
      ]
    }    
  ]
};

export default MainRoutes;