import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, ClickAwayListener, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";

import { appConstants } from "redux/constants";

const Search = () => {

  const dispatch = useDispatch();
  const { selectedSession, sessions } = useSelector((state) => state.app);
  const [openSelect, setOpenSelect] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleSelectClick = (event) => {      
      if (event.target.tagName === "LI" && event.target.classList.contains("MuiMenuItem-root")) {
        event.stopPropagation();
      }
    };

    document.addEventListener("click", handleSelectClick);

    return () => {
      document.removeEventListener("click", handleSelectClick);
    };
  }, []);

  const handleSelectClick = () => {
    setOpenSelect((prev) => !prev);
  };
  
  const handleCloseSelect = () => {
    setOpenSelect(false);
  };

  return (
    <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }}>
      <ClickAwayListener onClickAway={handleCloseSelect}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: "start", md: "end" }}
          sx={{ width: "100%" }}
          spacing={2}        
        >                          
          <FormControl sx={{minWidth: "12rem"}}>                        
            <Select                                                                             
              id="session"
              value={selectedSession}
              onChange={e => {
                dispatch({
                  type: appConstants.SET_SELECTED_SESSION,
                  payload: e.target.value
                })
                setOpenSelect(false);
              }}               
              onClick={handleSelectClick}
              open={openSelect}
              ref={selectRef}                             
            >                                      
              {sessions?.map((option) => (
                <MenuItem 
                  key={option?.id} 
                  value={option?.id} 
                  className="session-menu-item"                  
                >                
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Typography variant="body1">
                      {option?.name}
                    </Typography>
                    {option?.isCurrent && <Chip
                      label="CURRENT"
                      size="small"
                      variant="outlined"
                      color="success"
                    />}
                  </Stack>                
                </MenuItem>
              ))}        
            </Select>
          </FormControl>         
        </Stack>                                                           
      </ClickAwayListener>
    </Box>
  );
}

export default Search;