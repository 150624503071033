const states = [
  { "value": "uttarakhand", "label": "Uttarakhand" },
  { "value": "andhra-pradesh", "label": "Andhra Pradesh" },
  { "value": "arunachal-pradesh", "label": "Arunachal Pradesh" },
  { "value": "assam", "label": "Assam" },
  { "value": "bihar", "label": "Bihar" },
  { "value": "chhattisgarh", "label": "Chhattisgarh" },
  { "value": "goa", "label": "Goa" },
  { "value": "gujarat", "label": "Gujarat" },
  { "value": "haryana", "label": "Haryana" },
  { "value": "himachal-pradesh", "label": "Himachal Pradesh" },
  { "value": "jammu-and-kashmir", "label": "Jammu and Kashmir" },
  { "value": "jharkhand", "label": "Jharkhand" },
  { "value": "karnataka", "label": "Karnataka" },
  { "value": "kerala", "label": "Kerala" },
  { "value": "madhya-pradesh", "label": "Madhya Pradesh" },
  { "value": "maharashtra", "label": "Maharashtra" },
  { "value": "manipur", "label": "Manipur" },
  { "value": "meghalaya", "label": "Meghalaya" },
  { "value": "mizoram", "label": "Mizoram" },
  { "value": "nagaland", "label": "Nagaland" },
  { "value": "odisha", "label": "Odisha" },
  { "value": "punjab", "label": "Punjab" },
  { "value": "rajasthan", "label": "Rajasthan" },
  { "value": "sikkim", "label": "Sikkim" },
  { "value": "tamil-nadu", "label": "Tamil Nadu" },
  { "value": "telangana", "label": "Telangana" },
  { "value": "tripura", "label": "Tripura" },
  { "value": "uttar-pradesh", "label": "Uttar Pradesh" },  
  { "value": "west-bengal", "label": "West Bengal" },
]

const courseMode = [
  { "value": "YEARLY", "label": "Yearly" },
  { "value": "SEMESTER", "label": "Semester" },
]

const studentDocumentTypes = [
  { "value": "PHOTO", "label": "Photo" },
  { "value": "SIGNATURE", "label": "Signature" },
  { "value": "MARKSHEET", "label": "Marksheet" },
  { "value": "CERTIFICATE", "label": "Certificate" },
  { "value": "IDENTITY_DOCUMENT", "label": "Identity Document" },
  { "value": "OTHER", "label": "Other" },
]

const disabilityTypes = [
  { "value": "BLIND", "label": "Blind" },
  { "value": "DEAF", "label": "Deaf" },
  { "value": "DUMB", "label": "Dumb" },
  { "value": "PHYSICALLY_HANDICAPPED", "label": "Physically Handicapped" },
  { "value": "OTHER", "label": "Other" },
]

const genders = [
  { "value": "male", "label": "Male" },
  { "value": "female", "label": "Female" },
  { "value": "other", "label": "Others" },
]

const religions = [
  { "value": "hindu", "label": "Hindu" },
  { "value": "muslim", "label": "Muslim" },
  { "value": "christian", "label": "Christian" },
  { "value": "sikh", "label": "Sikh" },
  { "value": "buddhist", "label": "Buddhist" },
  { "value": "jain", "label": "Jain" },
  { "value": "other", "label": "Other" },
]

const nationalities = [
  { "value": "indian", "label": "Indian" },
  { "value": "nri", "label": "NRI" },
  { "value": "other", "label": "Other" },
]

const bloodGroups = [
  { "value": "A+", "label": "A+" },
  { "value": "A-", "label": "A-" },
  { "value": "B+", "label": "B+" },
  { "value": "B-", "label": "B-" },
  { "value": "AB+", "label": "AB+" },
  { "value": "AB-", "label": "AB-" },
  { "value": "O+", "label": "O+" },
  { "value": "O-", "label": "O-" },
]

const martialStatus = [
  { "value": "married", "label": "Married" },
  { "value": "unmarried", "label": "Unmarried" },
]

const identityDocTypes = [
  { "value": "aadhaar", "label": "Aadhaar" },
  { "value": "pan", "label": "PAN" },
  { "value": "passport", "label": "Passport" },
  { "value": "voter-id", "label": "Voter ID" },
  { "value": "driving-license", "label": "Driving License" },  
]

const marksheetTypes = [
  { "value": "10th", "label": "10th" },
  { "value": "12th", "label": "12th" },
  { "value": "diploma", "label": "Diploma" },
  { "value": "graduation", "label": "Graduation" },
  { "value": "post-graduation", "label": "Post Graduation" },  
]

const applicationStatus = [
  { "id": 1, "value": "APPLIED", "label": "Applied", "color": "warning", "next": [2, 3] },
  { "id": 2, "value": "APPROVED", "label": "Approved", "color": "success", "next": [3] },
  { "id": 3, "value": "REJECTED", "label": "Rejected", "color": "error", "next": [] },
  { "id": 4, "value": "WITHDRAWN", "label": "Withdrawn", "color": "error", "next": [] },
]

const sessionStudentStatus = [
  { "id": 1, "value": "ACTIVE", "label": "Active", "color": "primary" },
  { "id": 2, "value": "INACTIVE", "label": "Inactive", "color": "error" },
  { "id": 3, "value": "DROPPED", "label": "Dropped", "color": "error" },
  { "id": 4, "value": "COMPLETED", "label": "Completed", "color": "success" },
  { "id": 5, "value": "PROMOTED", "label": "Promoted", "color": "success" },  
  { "id": 6, "value": "YEAR_BACK", "label": "Year Back", "color": "error" },
]

const collegeUserRoles = [
  { "value": 1, "label": "Admin" },
  { "value": 2, "label": "Management" },
  { "value": 3, "label": "Faculty" }
]

const sessionStudentResult = [
  { "value": "PASS", "label": "Pass", "color": "success"},
  { "value": "FAIL", "label": "Fail", "color": "error"},
  { "value": "ABSENT", "label": "Absent", "color": "error"},
  { "value": "WITHHELD", "label": "Withheld", "color": "error"},
  { "value": "PROMOTED", "label": "Promoted", "color": "success"},
  { "value": "REPEAT", "label": "Repeat", "color": "error"},
  { "value": "COMPARTMENT", "label": "Compartment", "color": "error"},
  { "value": "IMPROVEMENT", "label": "Improvement", "color": "success"},
  { "value": "BACK", "label": "Back", "color": "error"},
]

const subjectTypes = [
  { "value": "MAJOR", "label": "Major" },
  { "value": "MINOR", "label": "Minor" },
  { "value": "CURRICULAR", "label": "Curricular" },
  { "value": "EXTRA_CURRICULAR", "label": "Extra Curricular" },
  { "value": "CO_CURRICULAR", "label": "Co Curricular" },
  { "value": "VOCAIONAL", "label": "Vocational" },
  { "value": "THEORY", "label": "Theory" },
  { "value": "PRACTICAL", "label": "Practical" }
]

const instructions = [
  "Candidates will be allowed to enter the examination hall half an hour before the commencement of the examination on the first day and then fifteen minutes before every day.",
  "No candidate will be allowed to enter the examination hall after half an hour of the commencement of the examination every day.",
  "Each candidate will have a fixed place in the examination room. The candidate will have to sit at his designated place only, otherwise not.",
  "Candidates will have to put their signatures in the test form once every day.",
  "The candidate is not allowed to leave his seat or go out of the examination hall without handing over the answer sheet to the invigilator before the end of the examination."
]

const warnings = [
  "Select your Subjects/Papers carefully. If you select the Subjects/Papers which have not been allotted to you by the College, Your Examination form will stand cancelled and you will not be allowed to write the Examination."
]

const personCategories = [
  { "value": "GENERAL", "label": "General" },
  { "value": "OBC", "label": "OBC" },
  { "value": "SC", "label": "SC" },
  { "value": "ST", "label": "ST" },
  { "value": "EWS", "label": "EWS" },
  { "value": "PWD", "label": "PWD" },
]

const LinkPrefix = ["http", "www", "mailto", "tel", "sms", "mailTo"]

const transactionModes = [
  { "value": "CASH", "label": "Cash" },
  { "value": "ONLINE-STRIPE", "label": "Stripe" },
  { "value": "ONLINE-RAZORPAY", "label": "Razorpay" },
]

module.exports = {
  states,
  courseMode,  
  studentDocumentTypes,
  disabilityTypes,
  genders,
  religions,
  nationalities,
  bloodGroups,
  martialStatus,
  identityDocTypes,
  marksheetTypes,
  applicationStatus, 
  collegeUserRoles,
  sessionStudentStatus,
  sessionStudentResult,
  subjectTypes,
  instructions,
  warnings,
  personCategories,
  LinkPrefix,
  transactionModes 
}