import { resultConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  students: [],
  total: 0,
  limit: 1,
  currentPage: 1,
  resultSaved: false,    
};

const resultReducer = (state = initState, action) => {  
  switch (action.type) { 
    case resultConstants.GET_STUDENTS_REQUEST:        
    case resultConstants.SAVE_STUDENT_RESULT_REQUEST:
    case resultConstants.UPDATE_RESULT_VISIBILITY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case resultConstants.GET_STUDENTS_SUCCESS:
      return {
        ...state,    
        students: action.payload.students,
        total: action.payload.total,
        limit: action.payload.limit,
        currentPage: action.payload.currentPage,   
        loading: false,
      };

    case resultConstants.SAVE_STUDENT_RESULT_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        resultSaved: true,
        loading: false,
      };

    case resultConstants.UPDATE_RESULT_VISIBILITY_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      };

    case resultConstants.GET_STUDENTS_FAILURE:
    case resultConstants.SAVE_STUDENT_RESULT_FAILURE:    
    case resultConstants.UPDATE_RESULT_VISIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  
              
    case resultConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null,
        resultSaved: false,        
      } 
      
    case resultConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default resultReducer;