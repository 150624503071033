import { formConstants } from "redux/constants";
import Axios from "utils/axios";

export const getForm = (id, module) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.GET_FORM_REQUEST });      
      const { data } = await Axios.get(`/college/${module}/form/${id}`);            
      if(module === 'admission'){
        dispatch({ 
          type: formConstants.GET_ADMISSION_FORM_SUCCESS,
          payload: data?.data
        });
      }else if(module === 'exam'){
        dispatch({ 
          type: formConstants.GET_EXAM_FORM_SUCCESS,
          payload: data?.data
        });
      }      
    }catch(error){
      dispatch({ 
        type: formConstants.GET_FORM_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getFormData = (id, filter, module) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.GET_FORM_DATA_REQUEST });
      const { page, limit, courseId, classId, status, isFeePaid, studentCategoryId, gender } = filter;
      let URL = `/college/${module}/form/${id}/data?page=${page}&limit=${limit}&courseId=${courseId}&classId=${classId}`;
      if(status) URL += `&status=${status}`;
      if(isFeePaid) URL += `&isFeePaid=${isFeePaid}`;
      if(studentCategoryId) URL += `&studentCategoryId=${studentCategoryId}`;
      if(gender) URL += `&gender=${gender}`;
      const { data } = await Axios.get(URL);
      if(module === 'admission'){
        dispatch({ 
          type: formConstants.GET_ADMISSION_FORM_DATA_SUCCESS,
          payload: data?.data
        });
      }else if(module === 'exam'){
        dispatch({ 
          type: formConstants.GET_EXAM_FORM_DATA_SUCCESS,
          payload: data?.data
        });
      }
    }catch(error){
      dispatch({ 
        type: formConstants.GET_FORM_DATA_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getStudentForm = (type, id, formId) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.GET_STUDENT_FORM_REQUEST });
      const { data } = await Axios.get(`/form/${type}/student/${formId}?studentId=${id}`);
      if(type === "admission"){
        dispatch({ 
          type: formConstants.GET_STUDENT_ADMISSION_FORM_SUCCESS,
          payload: data?.data
        });
      }else if(type === "exam"){
        dispatch({ 
          type: formConstants.GET_STUDENT_EXAM_FORM_SUCCESS,
          payload: data?.data
        });
      }
    }catch(error){
      dispatch({ 
        type: formConstants.GET_STUDENT_FORM_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const saveFormSubjectSelection = (form, type, studentId, formId) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.SAVE_FORM_SUBJECT_SELECTION_REQUEST });
      const { data } = await Axios.post(`/form/${type}/subject-selection/student`, form);
      dispatch({ 
        type: formConstants.SAVE_FORM_SUBJECT_SELECTION_SUCCESS,
        payload: {
          message: data?.message,
        }
      });
      if(type === "admission"){
        dispatch(getStudentForm("admission", studentId, formId));
      }else if(type === "exam"){
        dispatch(getStudentForm("exam", studentId, formId));
      }
    }catch(error){
      dispatch({ 
        type: formConstants.SAVE_FORM_SUBJECT_SELECTION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const payStudentFormFee = (form, type, studentId, formId) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.PAY_STUDENT_FORM_FEE_REQUEST });
      const { data } = await Axios.post(`/form/${type}/pay-fee/student`, form);
      dispatch({ 
        type: formConstants.PAY_STUDENT_FORM_FEE_SUCCESS,
        payload: {
          message: data?.message,
        }
      });
      if(type === "admission"){
        dispatch(getStudentForm("admission", studentId, formId));
      }else if(type === "exam"){
        dispatch(getStudentForm("exam", studentId, formId));
      }
    }catch(error){
      dispatch({ 
        type: formConstants.PAY_STUDENT_FORM_FEE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const notifyStudentForm = (type, form) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.NOTIFY_STUDENT_FORM_REQUEST });
      const { data } = await Axios.post(`/form/${type}/notify/student`, form);
      dispatch({ 
        type: formConstants.NOTIFY_STUDENT_FORM_SUCCESS,
        payload: {
          message: data?.message,
        }
      });      
    }catch(error){
      dispatch({ 
        type: formConstants.NOTIFY_STUDENT_FORM_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
};

export const handleAdmitCardVisibility = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.HANDLE_ADMIT_CARD_VISIBILITY_REQUEST });
      const { data } = await Axios.get(`/form/exam/admit-card/${id}`);
      dispatch({ 
        type: formConstants.HANDLE_ADMIT_CARD_VISIBILITY_SUCCESS,
        payload: {
          message: data?.message,
        }
      });
    }catch(error){
      dispatch({ 
        type: formConstants.HANDLE_ADMIT_CARD_VISIBILITY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}