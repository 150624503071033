import { combineReducers } from "redux";

import menuReducer from "./menu.reducer";
import userReducer from "./user.reducer";
import appReducer from "./app.reducer";
import dataReducer from "./data.reducer";
import admissionReducer from "./admission.reducer";
import formReducer from "./form.reducer";
import resultReducer from "./result.reducer";
import studentReducer from "./student.reducer";
import reportReducer from "./report.reducer";

const rootReducer = combineReducers({  
  admission: admissionReducer,
  app: appReducer,
  data: dataReducer,
  form: formReducer,
  menu: menuReducer,
  report: reportReducer,  
  result: resultReducer,
  student: studentReducer,
  user: userReducer,
});

export default rootReducer;