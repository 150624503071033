import { DashboardOutlined } from "@ant-design/icons";
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

const icons = {  
  BookOutlinedIcon,
  DashboardOutlined,
  GroupOutlinedIcon,
  SchoolOutlinedIcon
};

const dashboard = {
  id: "dashboard",
  title: "Navigation",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.DashboardOutlined,
      breadcrumbs: true,
      roles: [1, 2, 3]
    },
    {
      id: "courses",
      title: "Courses",
      type: "item",
      url: "/courses",
      icon: icons.BookOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2, 3]
    },
    {
      id: "faculties",
      title: "Faculties",
      type: "item",
      url: "/faculties",
      icon: icons.SchoolOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2, 3]
    },
    {
      id: "users",
      title: "Users",
      type: "item",
      url: "/users",
      icon: icons.GroupOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2]
    }   
  ]
};

export default dashboard;