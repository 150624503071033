import { admissionConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  admission: null,
  applicationData: null,
  onboardList: []
};

const admissionReducer = (state = initState, action) => {  
  let temp;
  switch (action.type) {    
    case admissionConstants.GET_ADMISSION_REQUEST:    
    return {
      ...state,
      admission: null,
      applicationData: null,
      onboardList: [],
      loading: true,
    };

    case admissionConstants.GET_ADMISSION_APPLICATIONS_REQUEST:
    case admissionConstants.GET_ONBOARD_LIST_REQUEST:
    case admissionConstants.UPDATE_APPLICATION_STATUS_REQUEST:
    case admissionConstants.ONBOARD_AS_STUDENTS_REQUEST:
      return {
        ...state,        
        loading: true,
      };

    case admissionConstants.GET_ADMISSION_SUCCESS:
      return {
        ...state,
        admission: action.payload,
        loading: false,
      };

    case admissionConstants.GET_ADMISSION_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applicationData: action.payload,
        loading: false,
      };

    case admissionConstants.GET_ONBOARD_LIST_SUCCESS:
      return {
        ...state,
        onboardList: action.payload,
        loading: false,
      };

    case admissionConstants.UPDATE_APPLICATION_STATUS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      };

    case admissionConstants.ONBOARD_AS_STUDENTS_SUCCESS:
      temp = state.admission;      
      temp.admissionCourses = temp.admissionCourses.map(course => {
        if(course.id === action.payload.data.courseId){
          course.onboardingDone = action.payload.data.onboardingDone;
        }
        return course;
      });
      return {
        ...state,
        admission: temp,
        message: action.payload.message,
        loading: false,
      };
        
    case admissionConstants.GET_ADMISSION_FAILURE:
    case admissionConstants.GET_ADMISSION_APPLICATIONS_FAILURE:    
    case admissionConstants.GET_ONBOARD_LIST_FAILURE:
    case admissionConstants.UPDATE_APPLICATION_STATUS_FAILURE:
    case admissionConstants.ONBOARD_AS_STUDENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case admissionConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case admissionConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default admissionReducer;