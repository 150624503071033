import { resultConstants } from "redux/constants";
import Axios from "utils/axios";

export const getResultStudents = (id, page) => {
  return async dispatch => {
    try{
      dispatch({ type: resultConstants.GET_STUDENTS_REQUEST });      
      const { data } = await Axios.get(`/result/get-students/${id}?page=${page}`);      
      dispatch({ 
        type: resultConstants.GET_STUDENTS_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: resultConstants.GET_STUDENTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const saveResult = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: resultConstants.SAVE_STUDENT_RESULT_REQUEST });
      const { data } = await Axios.post('/result/save/student', form);
      dispatch({ 
        type: resultConstants.SAVE_STUDENT_RESULT_SUCCESS,
        payload: {
          message: data.message
        }
      });      
    }catch(error){
      dispatch({ 
        type: resultConstants.SAVE_STUDENT_RESULT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const handleResultVisibility = (id, visibility, type) => {
  return async dispatch => {
    try{
      dispatch({ type: resultConstants.UPDATE_RESULT_VISIBILITY_REQUEST });
      const { data } = await Axios.post(`/result/visibility/${type}/${id}`, { visibility });
      dispatch({ 
        type: resultConstants.UPDATE_RESULT_VISIBILITY_SUCCESS,
        payload: {
          message: data.message
        }
      });
    }catch(error){
      dispatch({ 
        type: resultConstants.UPDATE_RESULT_VISIBILITY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
};