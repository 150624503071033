import { admissionConstants } from "redux/constants";
import Axios from "utils/axios";

export const getAdmission = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: admissionConstants.GET_ADMISSION_REQUEST });      
      const { data } = await Axios.get(`/college/admission/${id}`);
      dispatch({ 
        type: admissionConstants.GET_ADMISSION_SUCCESS,
        payload: { ...data?.data }
      });
    }catch(error){
      dispatch({ 
        type: admissionConstants.GET_ADMISSION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAdmissionApplications = (id, filter, page, limit) => {
  return async dispatch => {
    try{
      dispatch({ type: admissionConstants.GET_ADMISSION_APPLICATIONS_REQUEST });
      let URL = `/college/admission/${id}/applications?page=${page}&limit=${limit}`;
      if(filter?.status) URL += `&status=${filter.status}`;
      if(filter?.isFeePaid) URL += `&isFeePaid=${filter.isFeePaid}`;
      if(filter?.studentCategoryId) URL += `&studentCategoryId=${filter.studentCategoryId}`;
      if(filter?.gender) URL += `&gender=${filter.gender}`;
      if(filter?.category) URL += `&category=${filter.category}`;
      const { data } = await Axios.get(URL);
      dispatch({ 
        type: admissionConstants.GET_ADMISSION_APPLICATIONS_SUCCESS,
        payload: data?.data 
      });
    }catch(error){
      dispatch({ 
        type: admissionConstants.GET_ADMISSION_APPLICATIONS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getOnboardList = (id, status) => {
  return async dispatch => {
    try{
      dispatch({ type: admissionConstants.GET_ONBOARD_LIST_REQUEST });
      const { data } = await Axios.get(`/college/admission/${id}/onboard?status=${status}`);
      dispatch({ 
        type: admissionConstants.GET_ONBOARD_LIST_SUCCESS,
        payload: data?.data 
      });
    }catch(error){
      dispatch({ 
        type: admissionConstants.GET_ONBOARD_LIST_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updateApplicationStatus = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: admissionConstants.UPDATE_APPLICATION_STATUS_REQUEST });
      const { data } = await Axios.post(`/college/admission/${id}/update-status`, form);
      dispatch({ 
        type: admissionConstants.UPDATE_APPLICATION_STATUS_SUCCESS,
        payload: {
          message: data?.message,
          data: data?.data
        }
      });      
      if(data?.data) dispatch(getOnboardList(id, data?.data?.status));
    }catch(error){
      dispatch({ 
        type: admissionConstants.UPDATE_APPLICATION_STATUS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const onboardAsStudents = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: admissionConstants.ONBOARD_AS_STUDENTS_REQUEST });
      const { data } = await Axios.post(`/college/admission/${id}/onboard`, form);
      dispatch({ 
        type: admissionConstants.ONBOARD_AS_STUDENTS_SUCCESS,
        payload: {
          message: data?.message,
          data: data?.data
        }
      });            
    }catch(error){
      dispatch({ 
        type: admissionConstants.ONBOARD_AS_STUDENTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}