import { studentConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  students: [],
  total: 0,
  limit: 1,
  currentPage: 1,
  student: null
};

const studentReducer = (state = initState, action) => {  
  switch (action.type) { 
    case studentConstants.GET_STUDENTS_REQUEST:
    case studentConstants.MANAGE_STUDENT_REQUEST:
    case studentConstants.GET_STUDENT_REQUEST:
    case studentConstants.ADD_STUDENT_TO_COURSE_REQUEST:
    case studentConstants.UPDATE_ROLL_NUMBER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case studentConstants.GET_STUDENTS_SUCCESS:
      return {
        ...state,    
        students: action.payload.students,
        total: action.payload.total,
        limit: action.payload.limit,
        currentPage: action.payload.currentPage,   
        loading: false,
      };

    case studentConstants.MANAGE_STUDENT_SUCCESS: 
    case studentConstants.ADD_STUDENT_TO_COURSE_SUCCESS: 
    case studentConstants.UPDATE_ROLL_NUMBER_SUCCESS:      
      return {
        ...state,
        message: action.payload?.message,
        loading: false,
      };

    case studentConstants.GET_STUDENT_SUCCESS:
      return {
        ...state,
        student: action.payload,
        loading: false,
      };

    case studentConstants.GET_STUDENTS_FAILURE:
    case studentConstants.MANAGE_STUDENT_FAILURE:
    case studentConstants.GET_STUDENT_FAILURE:
    case studentConstants.ADD_STUDENT_TO_COURSE_FAILURE:
    case studentConstants.UPDATE_ROLL_NUMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  
              
    case studentConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null,
        resultSaved: false,        
      }

    case studentConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
}

export default studentReducer;
