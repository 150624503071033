import { studentConstants } from "redux/constants";
import Axios from "utils/axios";

export const getStudents = (page, limit, filter) => {
  return async dispatch => {
    try{
      dispatch({ type: studentConstants.GET_STUDENTS_REQUEST });      
      let URL = `/college/students?page=${page}&limit=${limit}`;
      if(filter?.name) URL += `&name=${filter.name}`;
      if(filter?.email) URL += `&email=${filter.email}`;
      if(filter?.mobile) URL += `&mobile=${filter.mobile}`;      
      const { data } = await Axios.get(URL);
      dispatch({ 
        type: studentConstants.GET_STUDENTS_SUCCESS,
        payload: data?.data
      });           
    }catch(error){
      dispatch({ 
        type: studentConstants.GET_STUDENTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageStudent = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: studentConstants.MANAGE_STUDENT_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/college/student/${id}`, form);
      }else{
        _data = await Axios.post(`/college/students`, form);
      }        
      const { data } = _data;
      dispatch({ 
        type: studentConstants.MANAGE_STUDENT_SUCCESS,
        payload: {
          message: data?.message,
        }
      });
      if(id) dispatch(getStudent(id));
    }catch(error){
      dispatch({ 
        type: studentConstants.MANAGE_STUDENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getStudent = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: studentConstants.GET_STUDENT_REQUEST });      
      const { data } = await Axios.get(`/college/student/${id}`);
      dispatch({ 
        type: studentConstants.GET_STUDENT_SUCCESS,
        payload: data?.data
      });           
    }catch(error){
      dispatch({ 
        type: studentConstants.GET_STUDENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const addStudentToCourse = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: studentConstants.ADD_STUDENT_TO_COURSE_REQUEST });      
      const { data } = await Axios.post(`/college/course/student`, form);
      dispatch({ 
        type: studentConstants.ADD_STUDENT_TO_COURSE_SUCCESS,
        payload: {
          message: data?.message,
        }
      });           
      if(form?.studentId) dispatch(getStudent(form?.studentId));
    }catch(error){
      dispatch({ 
        type: studentConstants.ADD_STUDENT_TO_COURSE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updateRollNumber = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: studentConstants.UPDATE_ROLL_NUMBER_REQUEST });                               
      const { data } = await Axios.post(`/college/student/roll-number`, form);
      dispatch({ 
        type: studentConstants.UPDATE_ROLL_NUMBER_SUCCESS,
        payload: {
          message: data?.message,
        }
      });
      if(id) dispatch(getStudent(id));
    }catch(error){
      dispatch({ 
        type: studentConstants.UPDATE_ROLL_NUMBER_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}