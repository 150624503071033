import { formConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,  
  admissionForm: null,  
  admissionFormData: null,
  studentAdmissionForm: null,
  examForm: null,
  examFormData: null,
  studentExamForm: null,
};

const formReducer = (state = initState, action) => {  
  switch (action.type) { 
    case formConstants.GET_FORM_REQUEST:
    case formConstants.GET_FORM_DATA_REQUEST:
    case formConstants.GET_STUDENT_FORM_REQUEST:    
    case formConstants.SAVE_FORM_SUBJECT_SELECTION_REQUEST:
    case formConstants.PAY_STUDENT_FORM_FEE_REQUEST:
    case formConstants.NOTIFY_STUDENT_FORM_REQUEST:
    case formConstants.HANDLE_ADMIT_CARD_VISIBILITY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case formConstants.GET_ADMISSION_FORM_SUCCESS:
      return {
        ...state,
        admissionForm: action.payload,
        loading: false,
      };

    case formConstants.GET_ADMISSION_FORM_DATA_SUCCESS:
      return {
        ...state,
        admissionFormData: action.payload,
        loading: false,
      };

    case formConstants.GET_EXAM_FORM_SUCCESS:
      return {
        ...state,
        examForm: action.payload,
        loading: false,
      };

    case formConstants.GET_EXAM_FORM_DATA_SUCCESS:
      return {
        ...state,
        examFormData: action.payload,
        loading: false,
      };

    case formConstants.GET_STUDENT_ADMISSION_FORM_SUCCESS:
      return {
        ...state,
        studentAdmissionForm: action.payload,
        loading: false,
      };

    case formConstants.GET_STUDENT_EXAM_FORM_SUCCESS:
      return {
        ...state,
        studentExamForm: action.payload,
        loading: false,
      };

    case formConstants.SAVE_FORM_SUBJECT_SELECTION_SUCCESS:
    case formConstants.PAY_STUDENT_FORM_FEE_SUCCESS:
    case formConstants.NOTIFY_STUDENT_FORM_SUCCESS:
    case formConstants.HANDLE_ADMIT_CARD_VISIBILITY_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      };

    case formConstants.GET_FORM_FAILURE:
    case formConstants.GET_FORM_DATA_FAILURE:
    case formConstants.GET_STUDENT_FORM_FAILURE:
    case formConstants.SAVE_FORM_SUBJECT_SELECTION_FAILURE:
    case formConstants.PAY_STUDENT_FORM_FEE_FAILURE:
    case formConstants.NOTIFY_STUDENT_FORM_FAILURE:
    case formConstants.HANDLE_ADMIT_CARD_VISIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  
              
    case formConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case formConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default formReducer;